import layouts from "./layouts"

export interface Project {
    mainColor: string,
    defaultScene: string;
    scenes: Scene[];
    // typeColors: number[][];
    layouts: Layouts;
    price: boolean;
    baseBright: number;
    filterCheck: any[];
    additionalFilterCheck: any[];
    price_condition: any;
    tooltip: {
        image_icons: boolean
    }
}
export interface Layouts {
    [key: string]: Layout;
}

export interface Layout {
    leftMenu?: LeftMenu[];
    image?: string
    [key: string]: any;
}

export interface LeftMenu {
    text: string;
    action: string;
    target: string;
    icon?: string;
}

export interface Scene {
    id: string;
    orbit?: string;
    layout: string;
    [key: string]: any;
}


export const project: Project = {
    mainColor: "5a7c91",
    defaultScene: "south",
    baseBright: 0.2,
    price: true,
    tooltip: {
        image_icons: false
    },
    price_condition: {
        text: "Prix ANRU TVA 5.5%",
        description: "Réservée aux personnes éligibles"
    },
    filterCheck: [
        { name: "T1", background: [255, 186, 0, 255], key: "type", value: "1", active: true, enabled: true },
        { name: "T2", background: [9, 128, 0, 255], key: "type", value: "2", active: true, enabled: true },
        { name: "T3", background: [0, 21, 181, 255], key: "type", value: "3", active: true, enabled: true },
        { name: "T4", background: [175, 0, 181, 255], key: "type", value: "4", active: true, enabled: true },
        // { name: "T5", background: [141, 0, 0, 255], key: "type", value: "5", active: true, enabled: true },
        // { name: "T6", background: [141, 65, 99, 255], key: "type", value: "6", active: true, enabled: true },
    ],
    additionalFilterCheck: [
        // { name: "Maison", background: [8, 77, 153, 255], key: "type_bien", value: "maison", active: true, enabled: true, additional: true },
        // { name: "Villa/Apt", background: [8, 77, 153, 255], key: "type_bien", value: "apt", active: true, enabled: true, additional: true },
    ],
    
    // Same as poi
    scenes: [
        // {
        //     id: "masse",
        //     orbit: "masse",
        //     layout: "base"
        // },
        // {
        //     id: "est",
        //     orbit: "est",
        //     layout: "base_lot_est"
        // },
        // {
        //     id: "est_F3",
        //     orbit: "est_F3",
        //     layout: "base_lot_est"
        // },
        // {
        //     id: "est_F2",
        //     orbit: "est_F2",
        //     layout: "base_lot_est"
        // },
        // {
        //     id: "est_F1",
        //     orbit: "est_F1",
        //     layout: "base_lot_est"
        // },


        // {
        //     id: "ouest",
        //     orbit: "ouest",
        //     layout: "base_lot_ouest"
        // },
        // {
        //     id: "ouest_F3",
        //     orbit: "ouest_F3",
        //     layout: "base_lot_ouest"
        // },
        // {
        //     id: "ouest_F2",
        //     orbit: "ouest_F2",
        //     layout: "base_lot_ouest"
        // },
        // {
        //     id: "ouest_F1",
        //     orbit: "ouest_F1",
        //     layout: "base_lot_ouest"
        // },


        {
            id: "south",
            orbit: "south",
            layout: "base_lot_south"
        },
        {
            id: "south_F3",
            orbit: "south_F3",
            layout: "base_lot_south"
        },
        {
            id: "south_F2",
            orbit: "south_F2",
            layout: "base_lot_south"
        },
        {
            id: "south_F1",
            orbit: "south_F1",
            layout: "base_lot_south"
        },

        // {
        //     id: "Ciel",
        //     layout: "ciel"
        // },

        {
            id: "search",
            layout: "search"
        },
        {
            id: "favorites",
            layout: "favorites"
        },
    ],
    layouts: layouts
}


// - Pour les filtres par typologie, avoir une ligne "Maisons" et une autre "Villas / Appartements" pour les collectifs
// - pois filter sans T1
// - favorite toutes les images
// - rajouter prix condition
// - Mettre le filtre par typologie également sur la page plan masse
// = imprimer format paysage
// - Rajouter filtre prix et filtre taille parcelle (présent dans le flux)

